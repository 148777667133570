<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CounterpartyEditCard",
  props: {
    company_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      company: {
        id: 0,
        name: 'Test',
        phone: 'Test',
        director_name: 'Test',
        email: 'Test',
        address: 'Test',
        assigned_managers: []
      },
      is_loading_data: false,
      get_request: {
        status: 'loading',
        message: '',
        details: ''
      },
      put_request: {
        status: 'initial',
        message: '',
        details: ''
      },
      mode: 'view',

      responsible_managers_options: []
    }
  },
  methods: {
    async getCounterpartyDetails() {
      this.get_request.status = 'loading'
      try {
        const response = await axios.get(`/customer/companies/${this.$route.params.slug}/`)
        this.company = {
          ...response.data,
          assigned_managers: response.data.assigned_managers.map(i => {
            return {
              ...i,
              value: i.id,
              label: i.username
            }
          })
        }
      } catch (e) {
        this.get_request.status = 'error'
        try {
          this.get_request.message = e.response.data.detail || 'Something went wrong'
        } catch {
          this.get_request.message = 'Something went wrong'
        }
      }
      this.get_request.status = 'success'
    },
    async saveCounterparty() {
      this.put_request.status = 'loading'
      try {
        await axios.put(`customer/company_update/${this.company.id}/`, {
          name: this.company.name.trim(),
          phone: this.company.phone.trim(),
          director_name: this.company.director_name.trim(),
          email: this.company.email.trim(),
          address: this.company.address.trim(),
          assigned_managers: this.company.assigned_managers.map(i => i.value)
        })
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${this.capitalizeFirstLetter(this.company_type)} details saved successfully !`,
          showConfirmButton: false,
          timer: 3000
        })
        this.mode = 'view'
        this.put_request.status = 'initial'
        await this.getCounterpartyDetails()
      } catch (e) {
        this.put_request.status = 'error'
        try {
          this.put_request.message = e.response.data.detail || 'Couldn\'t save the details !'
        } catch {
          this.put_request.message = 'Couldn\'t save the details !'
        }
      }
    },
    async changeToViewMode() {
      this.mode = 'view'
      this.put_request.status = 'initial'
      await this.getCounterpartyDetails()
    },

    capitalizeFirstLetter(string) {
      if (!string) return '-'
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    selectAssignedManager(manager) {
      let selected_managers_ids = this.company.assigned_managers.map(i => i.value)
      if (selected_managers_ids.includes(manager.value)) {
        this.company.assigned_managers = this.company.assigned_managers.filter(i => i.value !== manager.value)
      } else {
        this.company.assigned_managers.push(manager)
      }
    },
    async getInterRailManagers() {
      try {
        let response = await axios.get("/user/", {
          params: {
            company: 'interrail uzbekistan'
          }
        });
        this.responsible_managers_options = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
      } catch {
        alert("Could not search for managers")
      }
    },
  },
  mounted() {
    this.getCounterpartyDetails()
    this.getInterRailManagers()
  }
}
</script>

<template>
  <b-card no-body>
    <b-card-body class="pb-1">
      <b-card-title class="text-capitalize">{{ company_type }} Details</b-card-title>

      <form v-if="get_request.status === 'success'"
            @submit.prevent="saveCounterparty()" class="mt-3">
        <div class="mb-3">
          <label for="employeeName" class="form-label text-capitalize text-body">
            {{ company_type }} Name
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <input v-model="company.name" :readonly="mode === 'view'"
                 class="form-control" required>
        </div>

        <div class="mb-3">
          <label for="employeeName" class="form-label text-capitalize text-body">
            Phone Number
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <input v-model="company.phone" :readonly="mode === 'view'"
                 class="form-control" required type="tel">
        </div>

        <div class="mb-3">
          <label for="employeeName" class="form-label text-capitalize text-body">
            {{ company_type }} E-mail
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <input v-model="company.email" :readonly="mode === 'view'"
                 class="form-control" required type="email">
        </div>

        <div class="mb-3">
          <label for="employeeName" class="form-label text-capitalize text-body">
            Director Name
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <input v-model="company.director_name" :readonly="mode === 'view'"
                 class="form-control" required type="text">
        </div>

        <div class="mb-3">
          <label class="form-label text-capitalize text-body">
            Address
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <textarea v-model="company.address" rows="3"
                    class="form-control" required :readonly="mode === 'view'"/>
        </div>

        <div class="mb-3">
          <label class="form-label text-capitalize text-body">
            Responsible Managers
            <span v-if="mode === 'edit'" class="text-danger">*</span>
          </label>
          <div class="d-flex gap-3 flex-wrap bg-light p-3 rounded border border-soft-dark">

            <template v-if="mode === 'view'">
              <span v-for="manager in company.assigned_managers" :key="manager.value"
                    class="badge badge-soft-secondary">
                <router-link :to="{name: 'user_profile', params: {slug: manager.slug }}">
                  {{ manager.label }}
                </router-link>
              </span>
            </template>

            <template v-else-if="mode === 'edit'">
              <span @click="selectAssignedManager(manager)"
                    v-for="manager in responsible_managers_options" :key="manager.value"
                    class="badge cursor-pointer"
                    :class="{
                'bg-primary text-light': company.assigned_managers.map(i => i.value).includes(manager.value),
                'bg-soft-primary text-primary': !company.assigned_managers.map(i => i.value).includes(manager.value),
                    }"
              >
                  {{ manager.label }}
              </span>
            </template>

          </div>
        </div>

        <div class="mb-3">
          <b-button v-if="mode === 'view'"
                    @click="mode = 'edit'" type="button"
                    class="w-100" variant="outline-primary">
            Edit Details
            <i class="mdi mdi-pencil"></i>
          </b-button>

          <div v-if="mode === 'edit'" class="d-flex justify-content-between gap-3">
            <b-button @click="changeToViewMode()" variant="light" class="btn-icon waves-effect">
              <i class="bx bx-arrow-back"></i>
            </b-button>
            <b-button
                :disabled="put_request.status === 'loading'"
                :type="put_request.status === 'loading'? 'button' : 'submit'"
                class="w-100" variant="success">
              Save Details
              <i v-if="put_request.status === 'initial'" class="mdi mdi-content-save"></i>
              <b-spinner class="ms-1" v-else-if="put_request.status === 'loading'"
                         style="width: 15px; height: 15px">
              </b-spinner>
            </b-button>
          </div>
        </div>

      </form>
      <div v-else-if="get_request.status === 'loading'" class="pt-3">
        <b-spinner></b-spinner>
      </div>
      <div v-else-if="get_request.status === 'error'" class="pt-3">
        <b-alert show variant="danger" class="border-0 mb-0 " role="alert">
          <strong>{{ get_request.message || 'Couldn\'t load counterparty details !' }}</strong>
        </b-alert>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>